import { Container, Text, List } from "./styles";
import { methods } from "../../utils/methods";
import Title from "../../components/Title";
import MethodCard from "../MethodCard";
import Button from "../Button";

export default function MethodsSection({ onClick }) {
  return (
    <Container>
      <Title>Nossa metodologia</Title>
      <Text>
        Entenda como oferecemos um ensino de excelência para formar
        profissionais completos em apenas 10 meses.
      </Text>
      <List>
        {methods.map((method, index) => (
          <li key={index}>
            <MethodCard method={method} />
          </li>
        ))}
        <li>
          <Button blueColor onClick={onClick} />
        </li>
      </List>
    </Container>
  );
}
