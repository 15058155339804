import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html, body, div, p, header, footer, main, section, nav, ul, li, a, button, input, img, article, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    border: none;
  }

  :root {
    --bg: #0D1826;
    --white: #fff;
    --highlight-blue: #112EF7;
    --blue-400: #007AFA;
    --blue-500: #007aff;
    --blue-600: #014995;
    --blue-700: #002B58;
    --blue-800: #00013c;
    --blue-900: #001122;
    --cyan-500: #00dbff;
    --red-500: #D94848;
    --black-400: #000007;
    --black-500: #000000;
    --gray-400: #686868;
    --gray-450: #707070;
    --gray-500: #656565;
    --gray-600: #535353;
    --gray-650: #262626;
    --gray-700: #1D1D1D;
    --gray-800: #191919;
    --gray-850: #141414;
    --gray-900: #121212;
    --max-width: 1120px;
    --text-color: #2d2d2d;
    --text-color-secondary: #fff;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%; //15px
    }
    @media (max-width: 720px) {
      font-size: 87.5%; //14px
    }
  }

  body {
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;

    &, * {
      box-sizing: border-box;
    }

    ::-webkit-scrollbar {
      background-color: var(--gray-650);
      width: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      background: #0000ff;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "JetBrains Mono", monospace;
  }

  p {
      line-height: 1.4;
  }

  button {
    font-family: 'JetBrains Mono', monospace;
    font-weight: 800;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
`;

export default GlobalStyle;
