export const faqContents = [
  {
    number: "01.",
    title: "Quem é a Kenzie Academy? Quando ela foi fundada?",
    response:
      "Somos uma escola de programação web, com sede nos EUA e metodologia do Vale do Silício para formação de desenvolvedores Full Stack em apenas 10 meses, com taxa de 91% de empregabilidade. Nossas aulas são ao vivo, com suporte em tempo real, e além da programação, nosso curso contempla também metodologia ágil e soft skills, além de uma forte preparação para entrevistas e um núcleo dedicado de empregabilidade. Além disso, fomos pioneiros a oferecer o ISA (Income Share Agreement) no Brasil, no qual o aluno só começa a pagar o curso depois de formado e ao alcançar uma renda de no mínimo R$ 2.000.",
  },
  {
    number: "02.",
    title: "Qual a duração do curso de programação da Kenzie?",
    response:
      "10 meses, sendo metade do curso voltado para formação Front End e metade para formação Back End.",
  },
  {
    number: "03.",
    title: "A Kenzie Academy vale a pena?",
    response:
      "Sim. Na Kenzie, você tem a oportunidade de começar uma carreira em tecnologia, a área mais promissora do mercado, que oferece boa remuneração e oportunidades. Temos centenas de alunos que já passaram por nossa instituição.",
  },
  {
    number: "04.",
    title: "O curso da Kenzie é técnico?",
    response:
      "Não. Trata-se de curso livre, com duração de 10 meses e focado em programação, mas que ensina também metodologia ágil e soft skills. E nos módulos finais do curso, os alunos têm a possibilidade de serem contratados pelas nossas mais de 500 empresas parceiras.",
  },
  {
    number: "05.",
    title: "Ganho um certificado?",
    response:
      "Sim. Nossa formação conta com certificado de curso livre que comprova o conhecimento “Full Stack” em programação.",
  },
  {
    number: "06.",
    title: "Quais os requisitos para ser aluno Kenzie?",
    response:
      "A Kenzie oferece as habilidades técnicas com as principais linguagens e tecnologias do mercado e as soft skills para o desenvolvimento de uma pessoa programadora Full-Stack (front-end e back-end). Você tem a possibilidade de acompanhar o curso ao vivo com total apoio e suporte de instrutores e monitores em tempo real. Mas não se preocupe: se não conseguir assistir as aulas ao vivo, você pode acessar no horário que conseguir! Assim tem total flexibilidade para estudar, quando, onde e como quiser! Usamos as mais modernas ferramentas de videochamada e comunicação, como Zoom, Slack e o Canvas que facilitam o aprendizado no ambiente online. Nosso conteúdo é 100% prático e atualizado trimestralmente de acordo com as mais atuais linguagens e tendências da programação.",
  },
];
