import Button from "../Button";
import { Container, ContentContainer, Title, Content } from "./styles";

export default function Banner({ secondVer = false, onClick }) {
  return (
    <Container secondVer={secondVer}>
      <ContentContainer>
        <Title>
          Venha para a escola de programação com o melhor suporte ao aluno!
        </Title>
        <Content>
          <p>
            O contato rápido entre alunos, facilitadores e instrutores é parte
            da metodologia da Kenzie.{" "}
            <strong> Nosso atendimento é em tempo real</strong> e as dúvidas são
            tiradas no menor tempo possível.
          </p>
          {secondVer ? (
            <Button color="#0000ff" onClick={onClick} />
          ) : (
            <Button color="rgba(178, 185, 197, 0.2)" onClick={onClick} />
          )}
        </Content>
      </ContentContainer>
    </Container>
  );
}
