import { StyledButton } from "./styles";
import arrow from "../../assets/arrow.svg";
import blueArrow from "../../assets/blue-arrow.svg";

const Button = ({ color, blueColor = false, onClick }) => {
  return (
    <StyledButton onClick={onClick} color={color} blueColor={blueColor}>
      <span>Quero ser programador(a)</span>
      <img
        src={blueColor ? blueArrow : arrow}
        alt="Seta"
        width="56"
        height="16"
      />
    </StyledButton>
  );
};

export default Button;
