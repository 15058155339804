import { useEffect, useState } from "react";
import Logo from "../../assets/logo.svg";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";

import {
  Background,
  Card,
  Check,
  CongratsBlock,
  Container,
  Header,
  List,
  Tendencies,
} from "./style";

export default function CompletedSubscription() {
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const modality = "Full Stack - Integral Online";

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setFirstName(searchParams.get("name") ?? "");
    document.title = "Inscrição concluída! | Curso Full-Stack";
  }, [location.search]);

  return (
    <>
      <Header>
        <a href="https://kenzie.com.br/" target="blank">
          <img src={Logo} alt="Kenzie Academy" />
        </a>
      </Header>

      <Background>
        <Container>
          <CongratsBlock>
            <Check>
              <div>
                <svg width="34" height="34" fill="none" viewBox="0 0 34 34">
                  <path
                    fill="#07B182"
                    d="M17 .333C7.817.333.333 7.817.333 17c0 9.183 7.484 16.667 16.667 16.667 9.183 0 16.667-7.484 16.667-16.667C33.667 7.817 26.183.333 17 .333zm7.967 12.834l-9.45 9.45a1.249 1.249 0 01-1.767 0L9.034 17.9a1.257 1.257 0 010-1.767 1.257 1.257 0 011.766 0l3.833 3.834L23.2 11.4a1.257 1.257 0 011.767 0 1.257 1.257 0 010 1.767z"
                  />
                </svg>
              </div>
            </Check>
            <h1>
              {firstName
                ? `${firstName}, parabéns pela inscrição!`
                : "parabéns pela inscrição!"}
            </h1>

            <p>
              Você acabou de se inscrever e deu o primeiro passo na formação da
              Kenzie <strong>{modality}.</strong> Fique atento, pois dentro de
              instantes você receberá uma mensagem por e-mail ou WhatsApp com as
              instruções para as próximas etapas do processo seletivo. Fique
              atento!
            </p>

            <p>
              Não recebeu nosso contato? Entre em contato com nosso Suporte{" "}
              <a
                href="https://wa.me/554191087575"
                target="_blank"
                rel="noreferrer"
              >
                clicando aqui.
              </a>
            </p>
          </CongratsBlock>

          <Tendencies>
            <h2>Fique por dentro das tendências de tecnologia</h2>
            <p>
              Entre para nosso grupo do Telegram e receba em primeira mão dicas,
              curadoria gratuita de conteúdos e hacks que vão facilitar sua vida
              de dev.
            </p>
            <a href="https://t.me/VouSerDev" target="_blank" rel="noreferrer">
              Clique aqui para entrar no grupo
            </a>
          </Tendencies>

          <h2 className="pad">
            que tal conhecer um pouco mais sobre a kenzie?
          </h2>
          <List>
            <Card type="cod-virada">
              <div className="image" />
              <div className="content">
                <p>
                  Bônus: Trilha de conteúdos com introdução a programação (com
                  certificado)
                </p>
                <a
                  href="https://kenzie.memberkit.com.br/invites/bYP3vQ/join"
                  target="_blank"
                  rel="noreferrer"
                >
                  Saiba mais aqui
                </a>
              </div>
            </Card>

            <Card type="youtube">
              <div className="image" />
              <div className="content">
                <p>
                  Comece a se preparar com o conteúdo do nosso canal do YouTube
                </p>
                <a
                  href="https://www.youtube.com/channel/UC6rcCbDzhVoIm1V7WnwPDIQ?sub_confirmation=1&utm_campaign=eventos&utm_medium=organico&utm_source=site"
                  target="_blank"
                  rel="noreferrer"
                >
                  Inscreva-se
                </a>
              </div>
            </Card>

            <Card type="blog">
              <div className="image" />
              <div className="content">
                <p>
                  Conheça nosso blog e saiba mais sobre o mercado de programação
                </p>
                <a
                  href="https://kenzie.com.br/blog/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Acesse aqui
                </a>
              </div>
            </Card>
          </List>
        </Container>
      </Background>
      <Footer bgColor="#000" />
    </>
  );
}
