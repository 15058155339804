import styled from "styled-components";
import { FiCheckSquare, FiAward } from "react-icons/fi";

export const Container = styled.div`
  padding: 2.5rem 0 1rem;

  @media (min-width: 1024px) {
    padding: 4.5rem 0 0;
  }
`;

export const Content = styled.div`
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;
  color: var(--white);
`;

export const Title = styled.h2`
  text-transform: uppercase;
  font-size: 1.625rem;
  font-weight: 800;
  line-height: 2.5rem;
  margin-bottom: 2.5rem;
  text-align: center;

  @media (min-width: 1024px) {
    font-size: 2.625rem;
    line-height: 3.5rem;
    text-align: center;
  }
`;

export const MethodsTable = styled.table`
  border-collapse: collapse;
  border-radius: 5px;
  box-shadow: 0px 3px 7px 0px #00000059;

  @media (min-width: 1024px) {
    font-size: 1rem;
    margin-bottom: 4rem;
    border-radius: 20px;
  }

  thead {
    background-color: var(--blue-500);
    font-size: 1.25rem;

    @media (min-width: 1024px) {
      font-size: 2rem;
    }
  }

  thead tr {
    th {
      width: 50%;
      text-align: center;
    }

    th.tridicional {
      border-top-right-radius: 5px;
      background-color: var(--red-500);

      @media (min-width: 1024px) {
        border-top-right-radius: 20px;
      }
    }

    th.kenzie {
      border-top-left-radius: 5px;
      background-color: var(--blue-400);

      @media (min-width: 1024px) {
        border-top-left-radius: 20px;
      }
    }
  }

  tbody {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    tr {
      color: var(--text-color);
    }

    tr.dark {
      background-color: #fff;
    }

    tr.light {
      background-color: #efefef;
    }
  }

  th,
  td {
    padding: 1rem 0.6rem;
    text-align: left;

    div {
      display: flex;
      align-items: center;
    }

    img {
      width: 1.1rem;
      margin-right: 1rem;
      display: none;

      @media (min-width: 1024px) {
        display: inline;
      }
    }

    p {
      margin: 0;
      display: inline;
    }

    @media (min-width: 1024px) {
      padding: 2rem;
    }
  }

  td.last-kenzie {
    border-bottom-left-radius: 5px;
    @media (min-width: 1024px) {
      border-bottom-left-radius: 20px;
    }
  }

  td.last-traditional {
    border-bottom-right-radius: 5px;
    @media (min-width: 1024px) {
      border-bottom-right-radius: 20px;
    }
  }
`;

export const TeamMessage = styled.div`
  width: 100%;
  max-width: var(--max-width);
  padding: 4rem 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 4rem;
  border-bottom: 1px solid #eaeaea;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  div.image-box {
    width: 100%;
    margin-top: 1.5rem;

    @media (min-width: 1024px) {
      margin-top: 0;
    }

    img {
      width: 100%;
    }
  }

  div.text-box {
    @media (min-width: 1024px) {
      height: 100%;
      padding-right: 3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    h3 {
      text-transform: uppercase;
      font-size: 1.625rem;
      font-weight: 800;
      text-align: left;
      margin-bottom: 2.5rem;
      color: #0000ff;

      @media (min-width: 1024px) {
        font-size: 2.25rem;
        max-width: 30rem;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.8rem;
      text-align: left;
      color: var(--text-color);

      @media (min-width: 1024px) {
        font-size: 1.125rem;
        line-height: 1.875rem;
      }

      strong {
        color: inherit;
      }
    }
  }
`;

export const Group = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 10px;
  background-color: #f5f5f5;

  @media (min-width: 1024px) {
    padding: 3rem 2.5rem;
    margin-bottom: 1rem;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 2rem 0;

  @media (min-width: 1024px) {
    width: 36rem;

    text-align: left;
    flex-direction: row;
  }

  img {
    object-fit: contain;
    margin-right: 1rem;
  }

  h4 {
    margin: 1rem 0;
    font-size: 1.625rem;
    font-weight: 800;
    color: #0000ff;
    text-transform: uppercase;

    @media (min-width: 1024px) {
      font-size: 2.25rem;
    }
  }

  p {
    margin: 0 auto;
    margin-left: 0;
    font-size: 1.2rem;
    line-height: 2rem;
    color: var(--text-color);

    @media (min-width: 1024px) {
      font-size: 1.125rem;
    }
  }
`;

export const CheckIcon = styled(FiCheckSquare)`
  font-size: 3.5rem;
  color: var(--cyan-500);
`;

export const AwardIcon = styled(FiAward)`
  font-size: 3.5rem;
  color: var(--cyan-500);
`;
