import styled from "styled-components";

export const Container = styled.header`
  height: 4.375rem;
  background-color: var(--white);
  position: relative;
  padding: 1rem 0;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  box-shadow: 2px 1px 2px 0 rgb(0 0 0 / 15%);

  transition: all 100ms ease-in;

  z-index: 10000;
`;

export const Content = styled.div`
  width: 80%;
  max-width: var(--max-width);
  margin: 0 auto;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-btn {
    background-color: transparent;
  }

  img.logo {
    object-fit: contain;

    @media (min-width: 1024px) {
      width: 150px;
    }
  }

  button:last-child {
    border-radius: 2px;
    height: 3.125rem;
    font-size: 0.75rem;
    width: 13.625rem;
    border: none;

    &:hover {
      background: var(--blue-800);
    }

    @media (min-width: 375px) {
      width: 15.625rem;
    }
    @media (min-width: 414px) {
      width: 17.5rem;
    }
    @media (min-width: 1024px) {
      width: 18rem;
    }
  }
`;
