import styled from "styled-components";
import bg from "../../assets/bg-about-us.png";

export const Wrapper = styled.section`
  background-color: #0a1e32;
`;

export const Container = styled.div`
  padding: 2.5rem 0 6rem;
  width: 85%;
  margin: 0 auto;
  max-width: var(--max-width);
  @media (min-width: 1024px) {
    padding: 4.5rem 0 10rem;
  }
`;

export const ContentContainer = styled.div`
  @media (min-width: 1024px) {
    display: flex;
  }
`;

export const Content = styled.div`
  color: #fff;

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    line-height: 1.6;
  }

  p {
    font-size: 1.125rem;
    line-height: 1.6;
    margin-bottom: 2.5rem;
  }

  @media (min-width: 1024px) {
    width: 60%;

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 4rem;
    }
  }
`;

export const Background = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    height: inherit;
    width: 40%;
    background: url(${bg}) no-repeat center;
    background-size: contain;
  }
`;
