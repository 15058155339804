import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import GlobalStyle from "./styles/global";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const tagManagerArgs = {
  gtmId: "GTM-NB5L2PM",
  dataLayer: [],
};
process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i,
    ],
  });

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <GlobalStyle />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
