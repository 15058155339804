import { ResultsWrapper, ResultsContent, StyledLink } from "./styles";
import laptop from "../../assets/laptop.jpg";
import arrow from "../../assets/arrow.svg";
import Button from "../Button";

export default function ResultSection({ secondVer = false }) {
  return (
    <ResultsWrapper>
      <ResultsContent>
        <h3>
          Já ajudamos milhares de alunos a acelerarem sua entrada no mercado de
          tecnologia!
        </h3>
        <div className="goals">
          <div className="goal">
            <span>+4000</span>
            Alunos matriculados
          </div>
          <div className="goal">
            <span>+500</span>
            Empresas parceiras
          </div>
          <div className="goal">
            <span>91%</span>
            Dos alunos empregados
          </div>
        </div>
        {secondVer ? (
          <Button />
        ) : (
          <StyledLink
            href={`https://kenzie.com.br/resultados${window.location.search}`}
            target="_blank"
            rel="noreferrer"
          >
            <span>Conheça nossos devs contratados</span>
            <img src={arrow} alt="Seta" width="56" height="16" />
          </StyledLink>
        )}
      </ResultsContent>
      <div className="image-box">
        <img src={laptop} alt="Laptop" />
      </div>
    </ResultsWrapper>
  );
}
