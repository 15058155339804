import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import InputMask from "react-input-mask";
import Container from "../Container";
import Title from "../Title";
import { Code, Dollar, Percentage, Star } from "./icons";
import { SubscriptionService } from "../../services/subscription-service";
import { admissionsApi } from "../../services/axios";
import { useFirstInteractionFields } from "../../hooks/useFirstInteractionFields";

import {
  Background,
  Content,
  TitleContainer,
  StyledForm,
  Flex,
  Button,
  Error,
} from "./styles";

export default function Form({ refProp }) {
  const [utms, setUtms] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const { buildFirstInteractionFields, setFirstInteractionInCookies } =
    useFirstInteractionFields();

  const MANDATORY_FIELD_MESSAGE = "Campo obrigatório!";
  const schema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup
      .string()
      .email("Email invalido")
      .matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/, {
        message: "E-mail inválido",
      })
      .required(MANDATORY_FIELD_MESSAGE),
    phone: yup
      .string()
      .matches(/\(\d{2}\)\s\d{4,5}-\d{4}/)
      .required(),
    payment_method: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(schema) });

  function buildParams(params) {
    const modalityParam =
      params["modality"] === "Integral - Online"
        ? "Full Stack - Turma Diurna"
        : "Full Stack - Turma Noturna";

    return `?modality=${modalityParam}&name=${params.firstName}`;
  }

  async function onSubmit(data) {
    const firstInteraticonFields = await buildFirstInteractionFields();
    const subscriptionService = new SubscriptionService(admissionsApi);
    await subscriptionService.create({
      ...data,
      ...utms,
      ...firstInteraticonFields,
    });

    navigate(
      `/completed_subscription${buildParams({
        modality: "Integral - Online",
        firstName: data["first_name"],
      })}`
    );
  }

  const paymentOptions = [
    {
      label: "ISA | Pague apenas depois da formatura (VAGAS ESGOTADAS)",
      value: "ISA",
    },
    {
      label: "À vista | 1x de R$ 9.947",
      value: "À Vista",
    },
    {
      label: "Parcelado | 24x de R$ 625 (OPÇÃO MAIS ESCOLHIDA)",
      value: "Parcelado",
    },
  ];

  useEffect(() => {
    const randomId = `${Date.now()}${Math.random()
      .toString(36)
      .substring(2)}`.slice(0, 20);

    setUtms({
      utm_source: searchParams.get("utm_source") ?? "no-tracking",
      utm_medium: searchParams.get("utm_medium") ?? "no-tracking",
      utm_campaign: searchParams.get("utm_campaign") ?? "no-tracking",
      utm_content: searchParams.get("utm_content") ?? "no-tracking",
      utm_term: searchParams.get("utm_term") ?? "no-tracking",
      hsa_ad:
        searchParams.get("hsa_ad") ||
        searchParams.get("creative") ||
        "no-tracking",
      hsa_grp:
        searchParams.get("hsa_grp") ||
        searchParams.get("adgroupid") ||
        "no-tracking",
      hsa_cam:
        searchParams.get("hsa_cam") ||
        searchParams.get("campaignid") ||
        "no-tracking",
      hsa_src: searchParams.get("hsa_src") ?? "no-tracking",
      origin_release: searchParams.get("origem_lancamento") ?? "no-tracking",
      campaign_release:
        searchParams.get("campanha_lancamento") ?? "no-tracking",
      indicator_email: searchParams.get("EMAIL_DO_INDICADOR") ?? "no-tracking",
      indicator_name: searchParams.get("NOME_DO_INDICADOR") ?? "no-tracking",
      indicator: searchParams.get("utm_indicator") ?? "no-tracking",
      referral: searchParams.get("utm_referral") ?? "no-tracking",
      recommendation_id:
        searchParams.get("utm_recommendation_id") ?? "no-tracking",
      gclid: searchParams.get("gclid") ?? "no-tracking",
      required_equipment: "Pending answer",
      availability: "Pending answer",
      transaction_id: `vouserdev-${randomId}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  setFirstInteractionInCookies(searchParams);

  return (
    <Background>
      <Container paddingTopOnly>
        <Content ref={refProp}>
          <TitleContainer data-aos="fade-right">
            <Title
              color="var(--text-color-secondary)"
              margin={{ mobile: 1, desktop: 1 }}
            >
              Faça sua inscrição agora e inicie sua formação com desconto
              especial!
            </Title>
            <div>
              <Percentage />
              <Code />
              <Dollar />
              <Star />
            </div>
          </TitleContainer>
          <StyledForm onSubmit={handleSubmit(onSubmit)} data-aos="fade-left">
            <Flex>
              <div>
                <label htmlFor="firstName">Nome</label>
                <input
                  id="firstName"
                  type="text"
                  placeholder="Digite aqui seu nome"
                  required
                  {...register("first_name")}
                />
              </div>

              <div>
                <label htmlFor="lastName">Sobrenome</label>
                <input
                  id="lastName"
                  type="text"
                  placeholder="Digite aqui seu sobrenome"
                  required
                  {...register("last_name")}
                />
              </div>
            </Flex>

            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="text"
              placeholder="Digite aqui o seu e-mail"
              required
              {...register("email")}
            />
            {errors.email && <Error>{errors.email.message}</Error>}

            <label htmlFor="phone">Telefone</label>
            <InputMask
              id="phone"
              mask="(99) 99999-9999"
              pattern="\([0-9]{2}\)\s9[0-9]{4}-[0-9]{4}"
              type="tel"
              placeholder="Digite aqui o seu telefone"
              required
              {...register("phone")}
            />

            <label htmlFor="paymentMethod">Como deseja pagar o curso?</label>
            <select id="paymentMethod" {...register("payment_method")} required>
              <option value="">Opções de pagamento</option>
              {paymentOptions.map((opt) => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>

            <label htmlFor="computer" className="checkbox-label">
              <input
                id="computer"
                type="checkbox"
                required
                {...register("computer")}
              />
              Tenho internet e computador próprios
            </label>
            <label htmlFor="age" className="checkbox-label">
              <input id="age" type="checkbox" required {...register("age")} />
              18 anos completos
            </label>
            <label htmlFor="kenzie" className="checkbox-label">
              <input
                id="kenzie"
                type="checkbox"
                required
                {...register("kenzie")}
              />
              Estou ciente que a Kenzie é a escola de programação com uma das
              melhores taxas de empregabilidade do mercado
            </label>

            <Button type="submit">quero me inscrever</Button>

            <p className="terms">
              Na qualidade de Usuário declaro que li, compreendi e estou de
              acordo com os{" "}
              <a
                href="https://inscricao.kenzie.com.br/termos-condicoes"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Termos de Uso e com a Política de Privacidade.
              </a>
            </p>
          </StyledForm>
        </Content>
      </Container>
    </Background>
  );
}
