import styled from "styled-components";

export const Container = styled.div`
  padding: 1.875rem 0 0;

  @media (min-width: 1024px) {
    padding: 2.25rem 0 4.5rem;
    width: 80%;
    max-width: var(--max-width);
    margin: 0 auto;
  }

  @media (min-width: 1200px) {
    width: 70%;
  }
`;

export const TitleContainer = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  width: 80%;
  margin-bottom: 1.875rem;

  p {
    margin-top: 1rem;
  }

  @media (min-width: 360px) {
    width: 85%;
  }

  @media (min-width: 400px) {
    width: 85%;
  }

  @media (min-width: 450px) {
    width: 80%;
  }

  @media (min-width: 1024px) {
    max-width: none;
    width: 100%;
    margin-bottom: 1.125rem;

    p {
      font-size: 1.25rem;
    }
  }
`;

export const List = styled.ul`
  @media (min-width: 1024px) {
    display: flex;
    gap: 1.5rem;
  }
`;

export const Card = styled.li`
  background: ${({ secondarySchema }) =>
    secondarySchema ? "#ECECED" : "var(--bg-blue)"};

  .card-content {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    padding: 0;

    .header-title {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    .header-title > h4 {
      display: flex;
    }

    .header-title > span {
      font-size: 0.8rem;
      padding: 0.55rem 1rem;
      font-weight: 700;
      color: #f7c82a;
      background-color: var(--blue-700);
      border-radius: 8px;
      text-transform: uppercase;
      text-align: center;
    }

    .item-content {
      div {
        margin-left: 0.5rem;
        p {
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 0.5rem;

          .sign {
            font-size: 0.5rem;
            font-weight: 700;
          }

          .before {
            font-size: 1.125rem;
            text-decoration: line-through;
          }

          .sign-after {
            font-size: 0.813rem;
            font-weight: 700;
            color: #112ef7;
          }

          .after {
            font-size: 1.625rem;
            font-weight: 700;
            color: #112ef7;
          }

          &.item-price {
            .disclaimer {
              font-size: 0.75rem;
              font-style: italic;
              display: block;
              margin-top: 0.875rem;
            }
          }
        }
      }
    }
  }

  h3 {
    background: ${({ secondarySchema }) =>
      secondarySchema ? "var(--bg)" : "#112EF7"};
    font-size: 1.625rem;
    text-align: center;
    color: var(--text-color-secondary);
    padding: 1rem 0;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
    padding: 0.5rem 0.675rem;
    border: 1px solid #112ef7;
    border-radius: 10px;
    color: #112ef7;
    width: fit-content;
  }

  p {
    &.discount {
      font-weight: 700;
      font-size: 0.75rem;
    }

    &.disclaimer {
      font-size: 0.75rem;
      font-style: italic;
      margin-top: 0.875rem;
    }
  }

  .header-price {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 0.875rem;
    div {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }
    p {
      font-size: 1.625rem;
      font-weight: 700;
      margin-top: -0.5rem;

      .sign {
        font-size: 1rem;
        display: inline-block;
        margin-right: 0.25rem;
      }
    }
  }

  @media (min-width: 360px) {
    .card-content {
      width: 85%;
    }
  }

  @media (min-width: 400px) {
    .card-content {
      width: 85%;
    }
  }

  @media (min-width: 450px) {
    .card-content {
      width: 80%;
    }
  }

  @media (max-width: 415px) {
    h4 {
      max-width: 14.875rem;
    }
  }

  @media (min-width: 1024px) {
    flex-grow: 1;
    border-radius: 18px;

    h3 {
      border-top-right-radius: 18px;
      border-top-left-radius: 18px;
      padding: 2rem 0;
      font-size: 1.875rem;
    }

    h4 {
      font-size: 1.25rem;
      padding: 0.55rem 1rem;
    }

    p {
      font-size: 1.25rem;
      &.discount {
        font-size: 0.875rem;
      }
    }

    .card-content {
      width: 100%;
      padding: 1.875rem 0;

      .header-title > span {
        font-size: 1.25rem;
      }

      .item-content {
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1.5rem;
          p {
            font-size: 1.125rem;

            &.item-price {
              max-width: 40rem;
              font-size: 0.875rem;
              border-left: 2px solid #c9c9c9;
              padding: 1rem 0 1rem 3rem;
            }

            &.value {
              min-width: 9.688rem;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .flex-content {
      display: flex;
      gap: 6.25rem;
    }
  }
`;

export const Stamps = styled.div`
  max-width: var(--max-width);
  margin: 1.875rem auto;
  width: 80%;
  border: 2px solid #f7c82a;
  border-radius: 10px;
  padding: 0.5rem;

  .content {
    display: flex;
    gap: 0.875rem;

    width: fit-content;

    svg {
      height: 30px;
      width: 30px;
    }

    p {
      font-size: 0.875rem;
      font-family: "Open Sans", sans-serif;
    }

    + .content {
      margin-top: 1rem;
    }
  }

  @media (min-width: 360px) {
    width: 70%;
  }

  @media (min-width: 768px) {
    .content {
      align-items: center;
    }
  }

  @media (min-width: 1024px) {
    margin: 3.125rem 0 0;
    width: 100%;

    .content {
      gap: 1.5rem;
      padding: 1rem;
      svg {
        width: 45px;
        height: 45px;
      }

      p {
        font-size: 1.125rem;
      }

      + .content {
        margin-top: 0;
      }
    }
  }
`;
