import BaseAPI from "./base-api";

export class SubscriptionService extends BaseAPI {
  async create(params) {
    try {
      const response = await this.http.post(`/subscriptions`, params);
      const subscription = response.data.data.subscription;
      return { subscription };
    } catch (error) {
      return { error: error.response?.data };
    }
  }
}
