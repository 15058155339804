import React from "react";
import logoImg from "../../assets/logoImgV2.png";
import logoImgMbl from "../../assets/logoImgMblV2.png";

import { Container, Content } from "./styles";
import Button from "../Button";

const Header = ({ secondVer = false, onClick }) => {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function ConditionalWrapper({ condition, wrapper, children }) {
    return condition ? wrapper(children) : children;
  }

  return (
    <Container>
      <Content>
        <ConditionalWrapper
          condition={secondVer}
          wrapper={(children) => (
            <button onClick={scrollToTop} className="logo-btn">
              {children}{" "}
            </button>
          )}
        >
          <picture>
            <source media="(min-width: 1024px)" srcSet={logoImg} />
            <img
              src={logoImgMbl}
              alt="Logo Kenzie Academy"
              className="logo"
              width="36"
              height="38"
            />
          </picture>
        </ConditionalWrapper>
        <Button color="#0000FF" onClick={onClick} />
      </Content>
    </Container>
  );
};

export default Header;
