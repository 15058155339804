import { Container, Content, Section } from "./styles";
import Dani from "../../assets/Dani.png";

export default function CEOMessage() {
  return (
    <Section>
      <Container>
        <img src={Dani} alt="Daniel Kriger" width={240} height={210} />
        <Content>
          <p>
            ”Nosso método foi desenvolvido por engenheiros do Vale do Silício
            (Califórnia - EUA) e já foi comprovado por centenas de estudantes
            que,<strong> em apenas 10 meses</strong>, concluíram seus estudos,
            desenvolvendo hard e soft skills de forma profissional com nosso
            time de ensino e hoje{" "}
            <strong>
              já estão no mercado de tecnologia, ganhando mais de 10 mil reais!
            </strong>
            ”
          </p>
          <span>Daniel Kriger | CEO da Kenzie</span>
        </Content>
      </Container>
    </Section>
  );
}
