import { Container, Title } from "./styles";
import Forbes from "../../assets/logo_forbes.png";
import Folha from "../../assets/logo_folha.png";
import TecMundo from "../../assets/logo_tecmundo.png";
import Exame from "../../assets/logo_exame.png";

export default function KenzieNaMidiaV2() {
  return (
    <>
      <Title>KENZIE NA MÍDIA</Title>
      <Container>
        <li>
          <img src={Forbes} alt="Forbes" width="100" height="24" />
          <hr />
          <p>
            ”Exclusivo: Kenzie quer substituir universidades no Brasil com
            cursos técnicos.
          </p>
          <p>
            A edtech diz acreditar que sua proposta vai ajudar a solucionar a
            escassez de profissionais de tecnologia no Brasil. A Brasscom estima
            um déficit de 150 mil pessoas para preencher vagas no setor nos
            próximos quatro anos.”
          </p>
        </li>
        <li>
          <img src={Folha} alt="Folha de São Paulo" width="100" height="24" />
          <hr />
          <p>
            ”Na startup de ensino remoto curitibana Kenzie, por exemplo, o aluno
            pode deixar para pagar o curso apenas depois de formado.
          </p>
          <p>
            Em vez de mensalidades fixas, quem deixa para quitar o curso depois
            passa a pagar à startup 17% do salário quando consegue um emprego.”
          </p>
        </li>
        <li>
          <img src={TecMundo} alt="TecMundo" width="100" height="24" />
          <hr />
          <p>
            ”A Escola Americana de Programação Kenzie Academy, com sede no
            Brasil, é uma ótima opção para quem quer ingressar no mercado de uma
            forma rápida e prática, por meio d eum curso 100% online, com aulas
            ao vivo e suporte em tempo real todos os dias.”
          </p>
        </li>
        <li>
          <img src={Exame} alt="Exame" width="100" height="24" />
          <hr />
          <p>
            ”A escola de programação Kenzie Academy recebe aporte de R$ 8
            milhões.
          </p>
          <p>
            Fundada em janeiro de 2020, a empresa adota um modelo em que o aluno
            pode estudar de graça e pagar somente após conseguir um emprego.”
          </p>
        </li>
      </Container>
    </>
  );
}
