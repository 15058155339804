import { MdExpandMore } from "react-icons/md";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import Title from "../Title";
import { faqContents } from "../../utils/faq";

import { Container, Content } from "./styles";

export default function FAQ() {
  return (
    <Container>
      <Title>Perguntas frequentes</Title>
      <Content>
        {faqContents.map((content, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<MdExpandMore />}>
              <h3>
                <span>{content.number}</span> {content.title}
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <p>{content.response}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </Content>
    </Container>
  );
}
