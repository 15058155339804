import { ImWhatsapp } from "react-icons/im";

import { Link, Container } from "./styles";

export default function FloatLink() {
  return (
    <Container>
      <Link
        href="https://wa.me/5541992516267"
        target="_blank"
        rel="noreferrer"
        title="Whatsapp"
      >
        <ImWhatsapp />
      </Link>
    </Container>
  );
}
