export function getCookie(cookieName) {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName))
    ?.split("=")[1];
}

export function setCookie({ name, value, opts = "" }) {
  document.cookie = `${name}=${value}; Domain=${process.env.REACT_APP_DOMAIN}; SameSite=strict; Secure; ${opts}`;
}
