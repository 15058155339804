import React from "react";
import salary1 from "../../assets/salary1.png";
import salary2 from "../../assets/salary2.png";
import salary3 from "../../assets/salary3.png";
import salary4 from "../../assets/salary4.png";
import { Container, Content } from "./styles";
import KenzieNaMidiaV2 from "../KenzieNaMidiaV2";

const PaymentSection = () => {
  return (
    <Container>
      <Content>
        <h2>Qual o tamanho da oportunidade no mercado?</h2>

        <div className="salary-box">
          <img src={salary1} alt="Notícia" />
          <img src={salary2} alt="Notícia" />
          <img src={salary3} alt="Notícia" />
          <img src={salary4} alt="Notícia" />
        </div>

        <h2 className="max-width">
          A KENZIE TRANSFORMA VOCÊ EM UMA PESSOA PROGRAMADORA FULL-STACK MESMO
          QUE:
        </h2>

        <div className="difficulties">
          <div className="difficulty">
            <p>#1</p>
            <span>Não tenha conhecimento prévio.</span>
          </div>
          <div className="difficulty">
            <p>#2</p>
            <span>
              Não seja formado em nenhum curso de graduação ou técnico.
            </span>
          </div>
          <div className="difficulty">
            <p>#3</p>
            <span>Não saiba por onde começar.</span>
          </div>
          <div className="difficulty">
            <p>#4</p>
            <span>Não saiba falar inglês.</span>
          </div>
          <div className="difficulty">
            <p>#5</p>
            <span>Não tenha recursos para investir.</span>
          </div>
        </div>

        <KenzieNaMidiaV2 />
      </Content>
    </Container>
  );
};

export default PaymentSection;
