import styled from "styled-components";
import laptop from "../../assets/laptop.jpg";

export const ResultsWrapper = styled.section`
  background-color: var(--gray-800);
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: var(--white);

  @media (min-width: 1024px) {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  div.image-box {
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) {
      background: url(${laptop});
      background-repeat: no-repeat;
      background-size: cover;
      max-height: 100%;
      padding-bottom: 1rem;
    }

    img {
      width: 100%;
      height: 100%;

      @media (min-width: 1024px) {
        object-fit: cover;
        display: none;
      }
    }
  }
`;

export const ResultsContent = styled.div`
  padding: 2rem;
  text-align: left;

  button {
    margin: 0 auto;
  }

  @media (min-width: 1024px) {
    padding: 6rem 3rem 6rem 5rem;

    button {
      width: calc(100% - 2rem);
      margin: 0;
      max-width: 41.5rem;
    }
  }

  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.625rem;
    font-weight: 700;
    margin-bottom: 2rem;

    @media (min-width: 1024px) {
      font-size: 2.25rem;
      margin-bottom: 3rem;
    }
  }

  div.goals {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
      margin-bottom: 2rem;
    }
  }

  div.goal {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 0.9rem;
    background-color: var(--white);
    box-shadow: 0px 3px 6px #00000045;
    margin-bottom: 1rem;
    font-family: "Jet Brains", monospace;
    color: var(--gray-500);
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 800;

    @media (min-width: 1024px) {
      width: 12.5rem;
      margin-right: 2rem;
    }

    span {
      color: #0000ff;

      font-size: 2rem;
      margin: 0.5rem 0;

      @media (min-width: 1024px) {
        font-size: 3.5rem;
      }
    }
  }
`;

export const StyledLink = styled.a`
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--white);
  border: 3px solid #ffffff;
  border-radius: 2px;
  padding: 1.065rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  max-width: 390px;
  transition: all 300ms ease-in-out;

  &:hover,
  &:focus {
    background-color: #0000ff;
    border-color: transparent;
  }

  img {
    margin-left: 1rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.375rem;
    justify-content: center;
    max-width: 41.5rem;
  }
`;
