import styled from "styled-components";

export const Section = styled.section`
  max-width: var(--max-width);
  margin: 0 auto;
  width: 85%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 2.5rem;
    margin-bottom: 2.5rem;
    color: #0000ff;
  }
  ul {
    li {
      width: 146px;
      height: 73px;
      img {
        object-fit: contain;
      }
    }

    li + li {
      margin-top: 1rem;
    }
  }
  @media (min-width: 768px) {
    ul {
      display: flex;
      column-gap: 7rem;
      flex-wrap: wrap;
      justify-content: center;

      li {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      max-width: 231px;
      padding: 0;
      font-size: 1.75rem;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 51.813rem;
      column-gap: 0;
    }
  }
  @media (min-width: 1200px) {
    ul {
      justify-content: center;
      column-gap: 2rem;
    }
  }
  @media (min-width: 1400px) {
    ul {
      justify-content: center;
      column-gap: 4.75rem;
    }
  }
`;
