import icon1 from "../assets/method-1.png";
import icon2 from "../assets/method-2.png";
import icon3 from "../assets/method-3.png";
import icon4 from "../assets/method-4.png";
import icon5 from "../assets/method-5.png";

export const methods = [
  {
    image: icon1,
    title: "Aprenda na prática",
    description:
      "Com um ensino baseado em projetos, os alunos Kenzie evoluem com base nas demandas do mercado. Ao colocar a mão na massa, o aprendizado torna-se muito mais rápido.",
  },
  {
    image: icon2,
    title: "Suporte em tempo real",
    description:
      "Um dos diferenciais da Kenzie é que vamos bem além das aulas gravadas. O contato ágil entre alunos, instrutores e equipe é parte da metodologia. Assim, as dúvidas são sempre respondidas no menor tempo possível.",
  },
  {
    image: icon3,
    title: "Conteúdo atualizado",
    description:
      "Aprenda tudo que mais interessa para entrar no mercado. Nosso currículo é atualizado e baseado no que as empresas realmente precisam. Em 10 meses, você se torna um profissional completo.",
  },
  {
    image: icon4,
    title: "Mercado de trabalho",
    description:
      "Temos parcerias com mais de 500 empresas, que já contratam alunos e alunas da Kenzie. Ou seja, a entrada no mercado de trabalho é muito mais rápida para quem estuda com a gente.",
  },
  {
    image: icon5,
    title: "Comunidade Kenzie",
    description:
      "Se você quer ter boas conexões, a Kenzie é a sua escolha. Afinal, a Kenzie conta com mais de 4000 alunos, além de facilitadores e instrutores, que facilitam imensamente o networking.",
  },
];
