import styled from "styled-components";

export const Background = styled.section`
  background-image: linear-gradient(
    to bottom,
    #fff 7%,
    #f5f5f5 7%,
    #f5f5f5 90%,
    #fff 90%
  );

  @media (min-width: 1024px) {
    background-image: linear-gradient(
      to bottom,
      #fff 14%,
      #f5f5f5 14%,
      #f5f5f5 88%,
      #fff 88%
    );
  }
`;
