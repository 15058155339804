import { Container, Content, Section, VideoContainer } from "./styles";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import Button from "../Button";

export default function Hero({ onClick }) {
  return (
    <Section>
      <Container>
        <Content>
          <h1>
            Torne-se profissional na programação em 10 meses, sem mensalidades.
            Pague só depois de se formar e ganhar mais que R$ 2.000!
          </h1>
          <p>
            Estude na única escola de programação com 91% de empregabilidade em
            até seis meses após formatura! Aprenda com suporte dedicado a você e
            em tempo real, faça agora a sua inscrição e abra as portas para as
            melhores vagas do mercado de trabalho.
          </p>
          <Button onClick={onClick} />
        </Content>
        <VideoContainer>
          <span>APERTE O PLAY E ASSISTA! ↓</span>
          <div>
            <LiteYouTubeEmbed
              id="PZL-nN2tRXI"
              title={`Formação Full Stack da Kenzie`}
              poster="maxresdefault"
              params="rel=0"
            />
          </div>
        </VideoContainer>
      </Container>
    </Section>
  );
}
