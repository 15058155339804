import styled from "styled-components";

export const Container = styled.div`
  padding: 1rem 0;
  width: 100%;
  margin: 0 auto;
  background-color: #0a1e32;
  color: var(--white);
  text-align: center;
  h2 {
    text-transform: uppercase;
    text-align: left;
    margin: 2.5rem 0;
    width: 80%;
    font-size: 1.625rem;
    font-weight: 800;
    @media (min-width: 1024px) {
      margin: 4.5rem 0;
      font-size: 2.25rem;
    }
  }
  h2.max-width {
    @media (min-width: 1024px) {
      max-width: 52rem;
    }
  }
`;

export const Content = styled.div`
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;
  div.news {
    width: 80%;
    margin: 0 auto;
    img {
      width: 100%;
      border-radius: 0.9rem;
      box-shadow: 0 0 1px 2px #35353545;
    }
  }
  div.salary-box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.5rem;
      margin-bottom: 8rem;
      width: 100%;
    }
    img {
      width: 100%;
      margin-bottom: 2rem;
      @media (min-width: 1024px) {
        margin: 0;
      }
    }
  }
  div.difficulties {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 1024px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0.5rem;
    }
    div.difficulty {
      width: 100%;
      background-color: var(--gray-700);
      margin-bottom: 2rem;
      padding: 2rem;
      text-align: left;
      border-radius: 0.9rem;
      box-shadow: 0 1px 1px 2px var(--gray-700);
      @media (min-width: 1024px) {
        width: 28rem;
        margin: 0;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
      }
      p {
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 4rem;
        font-weight: bold;
        color: #157dfb;
      }
      span {
        font-family: "Montserrat", sans-serif;
        line-height: 1.5rem;
      }
    }
  }
  div#form {
    width: 100%;
    margin: 0 auto;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  div {
    width: 100%;
  }
  div img {
    width: 100%;
  }
  @media (min-width: 1024px) {
    margin: 0 auto;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    div img {
    }
  }
`;

export const KenzieMidia = styled.div`
  width: 100%;
  margin: 2.5rem auto;
  padding: 0 2rem;
  border-radius: 0.9rem;
  p {
    color: var(--white);
    font-size: 2.265rem;
    font-weight: bold;
  }
  div.midia-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1024px) {
      margin: 0 auto;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
    }
    img {
      width: 100%;
      margin: 1rem 0;
      @media (min-width: 1024px) {
        width: 100%;
      }
    }
  }
`;

export const AboutUs = styled.div`
  width: 80%;
  max-width: var(--max-width);
  margin: 0 auto;
  border-radius: 0.9rem;
  border: 1px solid #707070;
  padding: 3rem 1.5rem;
  position: relative;
  text-align: left;
  margin-top: 8rem;
  @media (min-width: 1024px) {
    padding: 4rem;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 2rem 0;
    @media (min-width: 1024px) {
      font-size: 1.5rem;
    }
  }
  p {
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-bottom: 4rem;
  }
  img {
    object-fit: contain;
    position: absolute;
    top: -50px;
    right: calc(50% - 3.9rem);
  }
`;
