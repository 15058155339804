import styled from "styled-components";
import bgDesktop from "../../assets/bg-hero-desktop.jpg";

export const Section = styled.section`
  background-image: url(${bgDesktop});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
`;

export const Container = styled.div`
  padding: 8rem 0 4.5rem;
  width: 80%;
  max-width: var(--max-width);
  margin: 0 auto;

  @media (min-width: 1024px) {
    padding: 12rem 0 6rem;
    display: flex;
    gap: 2rem;
  }
`;

export const Content = styled.div`
  text-align: center;
  color: var(--white);
  width: 100%;
  max-width: 33rem;
  margin: 0 auto;

  @media (min-width: 1024px) {
    text-align: left;
  }

  h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    margin: 0;

    @media (min-width: 1024px) {
      font-size: 2.125rem;
      line-height: 1.2;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: normal;

    font-family: "Open Sans", sans-serif;
    margin: 1.5rem 0;
    span {
      font-size: 1rem;
      color: #fe8967;
      display: inline-flex;
      align-items: center;
    }

    @media (min-width: 1024px) {
      font-size: 1.25rem;
      line-height: 1.875rem;
      margin: 2rem 0;
    }
  }

  button {
    margin: 0 auto;

    @media (min-width: 1024px) {
      margin: 0;
    }
  }
`;

export const VideoContainer = styled.div`
  border-radius: 4px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: var(--white);
    font-family: "JetBrains Mono", sans-serif;
    font-weight: 800;
    font-size: 1.165rem;
    padding: 2.5rem 0;
  }
  div {
    height: 18.813rem;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    iframe {
      height: 100%;
      width: 100%;
    }
  }
  @media (min-width: 1024px) {
    div {
      height: 17.875rem;
      width: 28.813rem;
    }
    span {
      padding: 2rem 0;
      font-size: 1.5rem;
      margin-top: 3rem;
    }
  }
  @media (min-width: 1350px) {
    div {
      width: 31.813rem;
    }
    span {
      padding: 1rem 0;
      margin-top: 0.5rem;
    }
  }
  .yt-lite {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
