import React, { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import TestimonialsSection from "../../components/TestimonialsSection";
import Assurance from "../../components/Assurance";
import CEOMessage from "../../components/CEOMessage";
import DropDown from "../../components/DropDown";
import DailyRoutineSection from "../../components/DailyRoutineSection";
import PaymentSection from "../../components/PaymentSection";
import Footer from "../../components/Footer";
import ResultSection from "../../components/ResultSection";
import Banner from "../../components/Banner";
import AboutUs from "../../components/AboutUs";
import MethodsSection from "../../components/MethodsSection";
import Partners from "../../components/Partners";
import FloatLink from "../../components/FloatLink";
import Form from "../../components/Form";
import FAQ from "../../components/FAQ";

import { Background } from "./styles";
import InvestimentConditions from "../../components/InvestimentConditions";

const Home = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const ref = useRef(null);
  function scrollToRef() {
    if (ref.current)
      window.scrollTo({
        top: isDesktop ? ref.current.offsetTop - 180 : ref.current.offsetTop,
      });
  }
  return (
    <>
      <Header onClick={scrollToRef} />
      <FloatLink />
      <Hero onClick={scrollToRef} />
      <TestimonialsSection />
      <Banner onClick={scrollToRef} />
      <ResultSection />
      <MethodsSection onClick={scrollToRef} />
      <Partners />
      <Background>
        <CEOMessage />
        <DropDown />
        <DailyRoutineSection />
      </Background>
      <Assurance />
      <InvestimentConditions />
      <Form refProp={ref} />
      <PaymentSection />
      <AboutUs onClick={scrollToRef} />
      <FAQ />
      <Footer />
    </>
  );
};

export default Home;
