import React from "react";
import checked from "../../assets/icon_check.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { moduleContents } from "../../utils/contents";

import { Container, Section, HardSkillIcon, SoftSkillIcon } from "./styles";

const DropDown = () => {
  return (
    <Container>
      <h2>Como será o seu percurso de aprendizado?</h2>
      <Section>
        {moduleContents.map((content, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span>
                <b>{content.modulo}</b> Módulo
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="module">
                <h4>
                  <HardSkillIcon />
                  Hard Skills
                </h4>
                <div className="module-section">
                  {content.hardskills.map((skill, index) => (
                    <p key={index}>
                      <img src={checked} alt="" />
                      {skill}
                    </p>
                  ))}
                </div>
              </div>

              <div className="module">
                <h4>
                  <SoftSkillIcon />
                  Soft Skills
                </h4>
                <div className="module-section">
                  {content.softskills.map((skill, index) => (
                    <p key={index}>
                      <img src={checked} alt="" />
                      {skill}
                    </p>
                  ))}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </Section>
    </Container>
  );
};

export default DropDown;
