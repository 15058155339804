import { Container, Section } from "./styles";
import Ifood from "../../assets/ifood-v2.png";
import Letras from "../../assets/letras.webp";
import MadeiraMadeira from "../../assets/madeiramadeira-v2.png";
import James from "../../assets/james-v2.png";
import PicPay from "../../assets/picpay-v2.png";
import Boticario from "../../assets/boticario-v2.png";
import Mirum from "../../assets/mirum-v2.png";
import Creditas from "../../assets/creditas-v2.png";

export default function Partners() {
  return (
    <Section>
      <Container>
        <h2>+500 empresas parceiras DE CONTRATAÇÃO</h2>
        <ul>
          <li>
            <img src={Ifood} alt="Ifood" width={146} height={73} />
          </li>
          <li>
            <img src={Letras} alt="Letras" width={146} height={73} />
          </li>
          <li>
            <img
              src={MadeiraMadeira}
              alt="Madeira Madeira"
              width={146}
              height={73}
            />
          </li>
          <li>
            <img src={James} alt="James" width={146} height={73} />
          </li>
          <li>
            <img src={PicPay} alt="PicPay" width={146} height={73} />
          </li>
          <li>
            <img
              src={Boticario}
              alt="Grupo Boticário"
              width={146}
              height={73}
            />
          </li>
          <li>
            <img src={Creditas} alt="Creditas" width={146} height={73} />
          </li>
          <li>
            <img src={Mirum} alt="Mirum" width={146} height={73} />
          </li>
        </ul>
      </Container>
    </Section>
  );
}
