import styled, { css } from "styled-components";
import bg from "../../assets/banner-amanda.jpg";

export const Container = styled.section`
  background: #b2b9c5;
  padding: 2.5rem 0;
  margin: 0 auto;

  @media (min-width: 1024px) {
    max-width: var(--max-width);
    background: url(${bg}) no-repeat;
    background-size: cover;
    padding: 4.5rem 0;
    margin-bottom: 6rem;
    border-radius: 20px;
  }

  ${({ secondVer }) =>
    secondVer &&
    css`
      button {
        border-color: #0000ff;

        &:hover,
        &:focus {
          filter: brightness(0.8);
        }
      }
    `}
`;

export const ContentContainer = styled.div`
  width: 85%;
  max-width: var(--max-width);
  margin: 0 auto;

  @media (min-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 5rem;
  }
`;

export const Title = styled.h2`
  font-size: 1.625rem;
  font-weight: 800;
  color: var(--white);
  text-transform: uppercase;
  margin-bottom: 2.5rem;

  @media (min-width: 1024px) {
    font-size: 2.25rem;
    margin-bottom: 0;
    width: 27%;
  }
`;

export const Content = styled.div`
  color: var(--text-color);

  @media (min-width: 1024px) {
    width: 33%;
  }

  p {
    font-size: 1.15rem;
    margin-bottom: 2.5rem;

    @media (min-width: 1024px) {
      font-size: 1.125rem;
      line-height: 1.8;
    }
  }
`;
