export function Stamp() {
  return (
    <svg width="45" height="44" fill="none" viewBox="0 0 45 44">
      <path
        fill="#F8C808"
        d="M19.952 28.321a1.59 1.59 0 01-1.109-.447L13.78 22.95a1.505 1.505 0 010-2.157 1.61 1.61 0 012.218 0l3.955 3.846 8.998-8.75a1.611 1.611 0 012.218 0c.607.59.607 1.567 0 2.158l-10.107 9.827c-.293.285-.69.447-1.109.447z"
      />
      <path
        fill="#F8C808"
        d="M22.484 43.745c-1.318 0-2.636-.428-3.662-1.282l-3.306-2.767c-.335-.285-1.172-.57-1.611-.57h-3.6c-3.096 0-5.607-2.442-5.607-5.453v-3.48c0-.426-.293-1.22-.586-1.545l-2.825-3.235c-1.716-1.974-1.716-5.087 0-7.06l2.825-3.236c.293-.325.586-1.119.586-1.546v-3.5c0-3.01 2.51-5.452 5.608-5.452h3.62c.44 0 1.276-.306 1.611-.57l3.306-2.767c2.051-1.71 5.253-1.71 7.303 0l3.306 2.767c.335.285 1.172.57 1.612.57h3.557c3.097 0 5.608 2.441 5.608 5.452v3.46c0 .427.314 1.24.607 1.566l2.846 3.215c1.757 1.994 1.757 5.107 0 7.1l-2.846 3.215c-.293.326-.607 1.14-.607 1.567v3.459c0 3.011-2.511 5.453-5.608 5.453h-3.557c-.44 0-1.277.305-1.611.57l-3.307 2.766c-1.025.875-2.343 1.303-3.662 1.303zM10.306 7.67c-1.36 0-2.47 1.078-2.47 2.4v3.48c0 1.16-.564 2.645-1.339 3.52l-2.825 3.235c-.732.834-.732 2.278 0 3.113l2.825 3.235c.775.895 1.34 2.36 1.34 3.52v3.479c0 1.322 1.109 2.4 2.469 2.4h3.62c1.213 0 2.741.55 3.662 1.323l3.306 2.767c.858.712 2.365.712 3.223 0l3.306-2.767c.92-.753 2.448-1.322 3.662-1.322h3.557c1.36 0 2.47-1.079 2.47-2.401v-3.46c0-1.18.564-2.664 1.36-3.56l2.845-3.214c.733-.835.733-2.3 0-3.134l-2.846-3.214c-.795-.896-1.36-2.381-1.36-3.561v-3.439c0-1.322-1.109-2.4-2.47-2.4h-3.556c-1.214 0-2.742-.55-3.662-1.323l-3.306-2.767c-.858-.712-2.365-.712-3.223 0l-3.306 2.787c-.92.753-2.47 1.303-3.662 1.303h-3.62z"
      />
    </svg>
  );
}

export function Like() {
  return (
    <svg width="45" height="45" fill="none" viewBox="0 0 45 45">
      <path
        fill="#F7C82A"
        d="M22.516 44.99c-1.32 0-2.638-.44-3.664-1.32l-3.308-2.847c-.335-.293-1.172-.586-1.612-.586H10.31a5.61 5.61 0 01-5.61-5.61v-3.58c0-.44-.293-1.256-.586-1.591l-2.826-3.329c-1.717-2.03-1.717-5.234 0-7.264l2.826-3.33c.293-.334.586-1.15.586-1.59v-3.58a5.61 5.61 0 015.61-5.61h3.622c.44 0 1.277-.315 1.612-.587l3.308-2.847c2.052-1.759 5.255-1.759 7.306 0l3.308 2.847c.335.293 1.172.586 1.612.586h3.56a5.61 5.61 0 015.61 5.61v3.56c0 .44.314 1.277.607 1.612l2.826 3.308c1.759 2.051 1.759 5.254 0 7.306l-2.826 3.308c-.293.335-.586 1.172-.586 1.612v3.559a5.61 5.61 0 01-5.61 5.61h-3.56c-.44 0-1.277.314-1.612.586l-3.308 2.848a5.71 5.71 0 01-3.663 1.319zM10.31 7.871c-1.361 0-2.47 1.109-2.47 2.47v3.58c0 1.193-.566 2.721-1.32 3.622l-2.826 3.328c-.733.859-.733 2.345 0 3.203l2.826 3.329c.775.921 1.34 2.45 1.34 3.622v3.58c0 1.36 1.11 2.47 2.47 2.47h3.622c1.215 0 2.743.565 3.664 1.36l3.308 2.848c.858.733 2.365.733 3.224 0l3.307-2.847c.922-.775 2.45-1.361 3.664-1.361h3.559c1.36 0 2.47-1.11 2.47-2.47v-3.56c0-1.214.566-2.742 1.361-3.663l2.826-3.308c.733-.858.733-2.365 0-3.224l-2.826-3.307c-.774-.922-1.36-2.45-1.36-3.664v-3.56c0-1.36-1.11-2.47-2.471-2.47H31.12c-1.193 0-2.742-.565-3.664-1.36L24.15 3.643c-.859-.733-2.366-.733-3.224 0L17.617 6.49c-.921.774-2.45 1.36-3.664 1.36H10.31v.022z"
      />
      <path
        fill="#F7C82A"
        d="M26.681 32.889h-3.726c-.88 0-2.198-.272-2.973-1.047l-2.889-2.219 1.926-2.491 3.036 2.345c.21.146.586.25.9.25h3.727c.502 0 1.109-.439 1.214-.878l2.386-7.265c.063-.189.063-.314.042-.356-.042-.063-.188-.126-.398-.126h-3.914a2.575 2.575 0 01-1.947-.879 2.665 2.665 0 01-.608-2.093l.503-3.161c.042-.168-.084-.377-.21-.42-.125-.02-.313.022-.376.085l-3.999 5.945-2.617-1.758 4.02-5.967c.837-1.256 2.596-1.8 4.02-1.277 1.59.524 2.616 2.324 2.26 3.957l-.377 2.386h3.245c1.236 0 2.324.524 2.973 1.445.628.9.775 2.073.377 3.182l-2.345 7.118c-.44 1.8-2.303 3.224-4.25 3.224z"
      />
      <path
        fill="#F7C82A"
        d="M16.006 31.821h-.984c-3.078 0-3.538-2.156-3.538-3.433V18.8c0-1.277.46-3.434 3.538-3.434h.984c3.077 0 3.538 2.157 3.538 3.434v9.588c0 1.277-.46 3.433-3.538 3.433zm-1.34-3.16c.084.02.21.02.377.02h.984c.167 0 .293 0 .376-.02 0-.064.021-.148.021-.273V18.8c0-.126 0-.21-.02-.272-.084-.021-.21-.021-.377-.021h-.984c-.168 0-.293 0-.377.02 0 .063-.021.147-.021.273v9.588c0 .125 0 .21.02.272z"
      />
    </svg>
  );
}
