import styled from "styled-components";

export const Container = styled.div`
  max-width: 350px;
  color: var(--white);
  background-color: #0a1e32;
  padding: 1.5rem 1rem;
  box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.2);

  @media (min-width: 1024px) {
    margin: 1rem 0 1rem 0;
    padding: 2rem;
    height: 350px;
    border-radius: 20px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
  }

  img {
    margin-right: 1rem;
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;

    h3 {
      font-size: 1.375rem;
    }

    img {
      margin-bottom: 1rem;
    }
  }
`;
