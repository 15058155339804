import React, { useState } from "react";

import { Container, Content, DailyOptions, DailyContent } from "./styles";

function DailyRoutineSection() {
  const dayByDaySteps = [
    {
      title: "Demo",
      time: "9h15 às 10h ou 19h30 às 20h40",
      note: "*Horário indicativo e sujeito a alterações",
      text1:
        "Hora de ver o Desenvolvedor em ação e ao vivo! Diferente de uma aula teórica, durante as demos, além de você entender melhor os conceitos de programação, você vai ver na prática como desenvolvedores seniores pensam, como resolvem problemas, quais atalhos usam e as boas práticas de um profissional de programação.",
      text2:
        "E o melhor de tudo: é interativo! Você pode perguntar ao vivo e tirar sua dúvida durante a demo. São dois horários de aula ao vivo que você pode escolher de segunda a sexta. Mas se perder alguma aula, não se preocupe: todas ficam gravadas para você assistir depois!",
    },
    {
      title: "Empregabilidade",
      time: "",
      text1:
        "Ao longo do curso, você terá total apoio do nosso time de Carreira e Empregabilidade para começar a trabalhar. Iremos te ajudar na criação de um portfólio, atualização de LinkedIn, preparação para entrevistas com recrutadores e até mesmo indicação para vagas abertas em nossas empresas parceiras.",
      text2: "Aqui na Kenzie, o seu sucesso é também o nosso sucesso!",
    },
    {
      title: "Exercícios e projetos",
      time: "",
      text1:
        "É fato que 70% do aprendizado vem da prática e, aqui na Kenzie, nossa metodologia prioriza justamente o 'aprender fazendo'. Acreditamos que somente colocando a mão na massa (diga-se teclado) que você realmente vai aprender a codar! Por isso, desde o primeiro dia você estará escrevendo linhas de código e fazendo projetos que simulam o dia a dia do mercado de trabalho.",
      text2:
        "E não se preocupe, você terá apoio e suporte em tempo real de monitores (alunos de turmas mais avançadas), dos facilitadores de ensino e instrutores, com muita experiência a compartilhar com você. Além claro, de ter oportunidade de desenvolver projetos individualmente e em equipe, o que vai te ajudar no seu progresso.",
    },
    {
      title: "Hora de desenvolver soft skills",
      time: "",
      text1:
        "Você ainda terá total suporte e apoio do nosso time de ensino para o desenvolvimento dos seus projetos e também terá conteúdos e orientações do nosso time de Sucesso e Colocação do Aluno(a) para desenvolver suas soft skills, que hoje são tão valorizadas, quanto as hard skills no mercado.",
      text2:
        "Com todo esse pacote da Kenzie, você sairá do curso como uma das pessoas programadoras mais requisitadas do mercado. Estamos empolgados de ver todo o sucesso que você vai ter na carreira de tecnologia!",
    },
  ];

  const [currentButton, setCurrentButton] = useState("demo");
  const [currentDailyStep, setCurrentDailyStep] = useState(dayByDaySteps[0]);

  const handleOptionClick = (name, step) => {
    setCurrentButton(name);
    setCurrentDailyStep(dayByDaySteps[step]);
  };

  return (
    <Container>
      <h2>E seu dia a dia de estudo?</h2>

      <Content>
        <DailyOptions>
          <button
            onClick={() => handleOptionClick("demo", 0)}
            className={currentButton === "demo" && "active"}
          >
            Demo
          </button>
          <button
            onClick={() => handleOptionClick("empregabilidade", 1)}
            className={currentButton === "empregabilidade" && "active"}
          >
            Empregabilidade
          </button>
          <button
            onClick={() => handleOptionClick("projetos", 2)}
            className={currentButton === "projetos" && "active"}
          >
            Projetos
          </button>
          <button
            onClick={() => handleOptionClick("soft", 3)}
            className={currentButton === "soft" && "active"}
          >
            Soft Skills
          </button>
        </DailyOptions>
        <DailyContent>
          <h4>{currentDailyStep.title}</h4>
          <span>{currentDailyStep.time}</span>
          {currentDailyStep.note && (
            <span className="note">{currentDailyStep.note}</span>
          )}

          <div>
            <p>{currentDailyStep.text1}</p>
            <p>{currentDailyStep.text2}</p>
          </div>
        </DailyContent>
      </Content>
    </Container>
  );
}

export default DailyRoutineSection;
