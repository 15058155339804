import { Container, TitleContainer } from "./styles";

export default function MethodCard({ method }) {
  const { image, title, description } = method;

  return (
    <Container>
      <TitleContainer>
        <img src={image} alt="Ícone ilustrativo" height="62" width="62" />
        <h3>{title}</h3>
      </TitleContainer>
      <p>{description}</p>
    </Container>
  );
}
