import styled from "styled-components";

export const Background = styled.div`
  background: #0a1e32;
  padding-top: 2rem;

  @media (min-width: 1024px) {
    padding-top: 2.25rem;
  }
`;

export const Content = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    gap: 1.5rem;
  }

  @media (min-width: 1400px) {
    gap: 5.625rem;
  }
`;

export const TitleContainer = styled.div`
  margin-bottom: 1rem;
  div {
    display: flex;
    gap: 0.5rem;
  }

  @media (min-width: 1024px) {
    max-width: 20rem;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  p.terms {
    font-size: 0.625rem;
    font-family: "Open Sans", sans-serif;
    margin-top: 1.375rem;
    color: #fff;
    max-width: none;

    @media (min-width: 1024px) {
      margin-top: 2rem;
      font-size: 0.75rem;
    }

    a {
      text-decoration: underline;
      color: #fff;
    }
  }

  label {
    display: block;
    width: 100%;
    color: var(--text-color-secondary);
    margin: 0 0 0.3rem 0.3rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;

    &.checkbox-label {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.25rem;
    }
  }

  input,
  select {
    width: 100%;
    height: 4rem;
    padding: 1rem;
    font-size: 1.2rem;
    color: var(--text-color);
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 20px;
    outline: none;
    margin-bottom: 1rem;
    box-shadow: 3px 3px 4px 0 #00000026;
    border-radius: 5px;

    &[type="checkbox"] {
      width: auto;
      height: auto;
      margin: 0;
    }

    &:focus {
      box-shadow: 0 0 1px 1px #f7c82a;
    }
    &::-webkit-input-placeholder {
      font-size: 0.8rem;
    }

    @media (min-width: 400px) {
      font-size: 1.25rem;

      &::-webkit-input-placeholder {
        font-size: 1rem;
      }
    }

    @media (min-width: 1024px) {
      width: 100%;
      font-size: 1.125rem;
    }

    @media (min-width: 1200px) {
      &::-webkit-input-placeholder {
        font-size: 1rem;
      }
    }
  }

  select {
    font-size: 0.8rem;
    color: #909090;

    &:active,
    &:valid {
      color: var(--text-color);
    }

    option {
      color: var(--text-color);
    }

    @media (min-width: 400px) {
      font-size: 1rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.0875rem;
    }
  }

  textarea {
    width: 98%;
    padding: 0.313rem;
    margin: 0.625rem 0;
    resize: none;
    border: 1px solid transparent;
    outline: none;
    color: var(--text-color);
    border-radius: 5px;
    font-size: 1.2rem;
    height: 7.5rem;

    &:focus {
      border: 2px solid #f7c82a;
    }
  }
`;

export const Flex = styled.div`
  width: 100%;
  div {
    width: 100%;
  }
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    label {
      margin-left: -0.02rem;
    }
  }
`;

export const Button = styled.button`
  background: #f7c82a;
  color: var(--text-color);
  width: 100%;
  height: 3.125rem;
  max-width: 18.75rem;
  padding: 1rem 0;
  border-radius: 140px;
  font-family: "JetBrains Mono", sans-serif;
  font-weight: 700;
  font-size: 0.775rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: box-shadow 0.3s ease;
  margin-top: 1rem;

  &:hover {
    box-shadow: 0 0 16px 4px #f7c82a;
  }

  @media (min-width: 350px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.25rem;
    height: 3.75rem;
  }
`;

export const Error = styled.span`
  display: block;
  padding: 1rem 0;
  width: fit-content;
  color: #ff4e4e;
  margin: 0 auto;
`;
