import styled from "styled-components";

import { FaLaptopCode, FaGlobe } from "react-icons/fa";

export const Container = styled.section`
  width: 85%;
  margin: 0 auto;
  color: #0000ff;
  text-align: center;
  max-width: var(--max-width);
  padding-bottom: 2.5rem;

  @media (min-width: 1024px) {
    padding-bottom: 4.5rem;
  }

  h2 {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 3.125rem;
    font-size: 1.625rem;
    font-weight: 800;
    line-height: 2.5rem;
    color: #0000ff;

    @media (min-width: 1024px) {
      font-size: 2.25rem;
      line-height: 3.5rem;
    }
  }
`;

export const Section = styled.div`
  div {
    background-color: transparent;
    color: var(--text-color);
    font-size: 1.5rem;
    text-align: left;

    span {
      font-weight: 800;
      text-transform: uppercase;
      font-family: "JetBrains Mono", sans-serif;
    }
  }

  div.MuiAccordionSummary-root {
    background-color: var(--white);
    border-radius: 0.5rem;
  }

  div.MuiAccordionSummary-content {
    font-size: 1.2rem;
    padding: 0.1rem 0.5rem;
    color: var(--text-color);
    span {
      display: flex;
      align-items: center;
      font-size: 1.625rem;

      b {
        margin-right: 5px;
      }
    }
  }

  .MuiSvgIcon-root {
    path {
      fill: var(--text-color);
    }
  }

  .MuiAccordion-root:before {
    background: none;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  div.MuiAccordion-rounded {
    border-radius: 0.5rem;
    margin: 2rem 0;

    div.MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      background-color: var(--white);

      @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem;
      }

      div.module {
        h4 {
          font-size: 1.2rem;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-weight: 800;
          margin-bottom: 1.5rem;

          @media (min-width: 1024px) {
            font-size: 2rem;
          }
        }

        div.module-section {
          display: flex;
          flex-direction: column;
          font-size: 1.2rem;

          p {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            @media (min-width: 1024px) {
              font-size: 1.5rem;
            }

            img {
              width: 1.5rem;
              margin-right: 0.5rem;

              @media (min-width: 1024px) {
                width: 2.5rem;
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const HardSkillIcon = styled(FaLaptopCode)`
  font-size: 2.5rem;
  color: var(--blue-500);
  margin-right: 0.5rem;
`;

export const SoftSkillIcon = styled(FaGlobe)`
  font-size: 2.5rem;
  color: var(--blue-500);
  margin-right: 0.5rem;
`;
