import CompletedSubscription from "../pages/CompletedSubscription";
import Home from "../pages/Home";

import { Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route
        path="/completed_subscription"
        element={<CompletedSubscription />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
