import styled from "styled-components";

export const Container = styled.ul`
  margin: 0 auto;

  li {
    background: var(--white);
    border-radius: 10px;
    padding: 1.5rem;
    width: 100%;
    img {
      margin-bottom: 1.5rem;
      object-fit: contain;
      display: block;
    }
    hr {
      background: #e2e2e2;
    }
    p {
      color: var(--text-color);
      padding-top: 1rem;
      text-align: left;
    }
  }

  li + li {
    margin-top: 1.5rem;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    li {
      max-width: 19rem;
    }
    li + li {
      margin-top: 0;
    }
  }
  @media (min-width: 1024px) {
    gap: 1rem;
    li {
      max-width: 16.5rem;
    }
  }
`;

export const Title = styled.h2`
  text-transform: uppercase;
  text-align: left;
  font-size: 1.625rem;
  font-weight: 800;
  margin: 0;

  @media (min-width: 1024px) {
    font-size: 2.25rem;
  }
`;
