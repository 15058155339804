import styled from "styled-components";
import codViradaMbl from "../../assets/cod-virada-mbl.webp";
import youtubeMbl from "../../assets/youtube-mbl.webp";
import blogMbl from "../../assets/blog-mbl.webp";
import codVirada from "../../assets/cod-virada.webp";
import youtube from "../../assets/youtube.webp";
import blog from "../../assets/blog.webp";

export const Header = styled.header`
  padding: 1.5rem 0;
  border-bottom: 1px solid #fff;
  background: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
    margin: 0 auto;
    display: block;
  }

  @media (min-width: 1024px) {
    padding: 2rem 0;

    img {
      width: 169px;
      height: 42px;
    }
  }
`;

export const Background = styled.main`
  background-image: linear-gradient(
    to bottom,
    var(--bg) 34%,
    var(--bg) 34%,
    #fff 34%,
    #fff 60%
  );

  @media (min-width: 420px) {
    background-image: linear-gradient(
      to bottom,
      var(--bg) 30%,
      var(--bg) 30%,
      #fff 30%,
      #fff 60%
    );
  }

  @media (min-width: 768px) {
    background-image: linear-gradient(
      to bottom,
      var(--bg) 26.5%,
      var(--bg) 26.5%,
      #fff 26.5%,
      #fff 60%
    );
  }

  @media (min-width: 1024px) {
    background-image: linear-gradient(
      to bottom,
      var(--bg) 30%,
      var(--bg) 30%,
      #fff 30%,
      #fff 60%
    );
  }

  @media (min-width: 1200px) {
    background-image: linear-gradient(
      to bottom,
      var(--bg) 42%,
      var(--bg) 42%,
      #fff 42%,
      #fff 60%
    );
  }
`;

export const Container = styled.div`
  padding: 1.5rem 0 2.5rem;
  margin: 0 auto;
  max-width: var(--max-width);
  width: 80%;

  h2 {
    font-size: 1.75rem;
    color: #0d1826;
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;

    &.pad {
      padding: 1.75rem 0;
    }
  }

  @media (min-width: 1024px) {
    width: 70%;
    padding: 3rem 0;

    h2 {
      font-size: 2.375rem;

      &.pad {
        padding: 3.125rem 2.5rem;
      }
    }
  }
`;

export const CongratsBlock = styled.div`
  h1 {
    text-transform: uppercase;
    color: var(--text-color-secondary);
    font-size: 1.75rem;
    font-weight: 800;
    text-align: center;
    margin-bottom: 1.125rem;
  }

  p {
    color: var(--text-color-secondary);
    font-size: 1rem;
    text-align: center;

    + p {
      margin-top: 1rem;
    }

    strong {
      font-size: 1rem;
    }

    a {
      color: #5792ff;
      font-size: 1rem;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 2.688rem;
      margin-bottom: 3rem;
    }
    p {
      font-size: 1.375rem;
      a {
        font-size: 1.375rem;
      }

      strong {
        font-size: 1.375rem;
      }
    }
  }
`;

export const Tendencies = styled.div`
  background: #d9e6ff;
  padding: 1.5rem 2.063rem;
  border-radius: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;

  p {
    text-align: center;
  }

  a {
    background: var(--highlight-blue);
    color: var(--text-color-secondary);
    padding: 0.5rem 1.25rem;
    font-size: 0.875rem;
    border-radius: 90px;
    height: 3.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "JetBrains Mono", sans-serif;
    font-weight: 700;
    max-width: 19.688rem;
    transition: box-shadow 0.3s ease-in-out;

    &:hover,
    &:focus {
      box-shadow: 0 0 16px 4px var(--highlight-blue);
    }
  }

  @media (min-width: 400px) {
    a {
      font-size: 1rem;
      padding: 0.5rem 3rem;
    }
  }

  @media (min-width: 768px) {
    padding: 1.5rem 3.5rem;
  }

  @media (min-width: 1024px) {
    gap: 1.875rem;
    padding: 1.875rem 3.2rem 2.625rem;
    margin-top: 3.125rem;

    p {
      font-size: 1.375rem;
    }

    a {
      max-width: 28.5rem;
      font-size: 1.125rem;
      padding: 0.5rem 3rem;
    }
  }

  @media (min-width: 1400px) {
    padding: 1.875rem 8.438rem 2.625rem;

    h2 {
      max-width: 48rem;
    }
  }

  @media (min-width: 1800px) {
    padding: 1.875rem 10.438rem 2.625rem;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;
  }
`;

export const Card = styled.li`
  display: flex;
  flex-direction: column;
  border-radius: 35px;
  max-width: 22.125rem;

  .image {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    height: 150px;

    background: ${({ type }) => {
      if (type === "cod-virada") return `url(${codViradaMbl})`;
      else if (type === "youtube") return `url(${youtubeMbl})`;
      else return `url(${blogMbl})`;
    }};

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .content {
    background: var(--highlight-blue);
    padding: 1.275rem 0.5rem;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    p {
      color: var(--text-color-secondary);
      text-align: center;
      font-weight: 700;
    }

    a {
      font-family: "JetBrains Mono", sans-serif;
      font-weight: 700;
      background: #ffffff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.35);
      border-radius: 90px;
      transition: filter 0.3s ease-in-out;
      height: 2.75rem;
      max-width: 11.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;

      &:hover,
      &:focus {
        filter: brightness(0.8);
      }
    }
  }
  + li {
    margin-top: 1.875rem;
  }

  @media (min-width: 400px) {
    .content {
      padding: 1.275rem 2rem;
    }
  }

  @media (min-width: 420px) {
    .content {
      padding: 1.275rem 2.8rem;
    }
  }

  @media (min-width: 1024px) {
    max-width: 21.25rem;

    .image {
      height: 260px;

      background: ${({ type }) => {
        if (type === "cod-virada") return `url(${codVirada})`;
        else if (type === "youtube") return `url(${youtube})`;
        else return `url(${blog})`;
      }};
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .content {
      gap: 1.5rem;
      padding: 1.275rem 1.3rem;
      p {
        font-size: 1.125rem;
      }

      a {
        font-size: 1.125rem;
        max-width: 14.75rem;
        padding: 0.5rem 2rem;
      }
    }
  }

  @media (min-width: 1200px) {
    flex-grow: 1;
    height: 29.375rem;
    width: 21.25rem;

    .content {
      height: 14.688rem;
    }

    + li {
      margin-top: 0;
    }
  }

  @media (min-width: 1500px) {
    height: auto;

    .content {
      height: auto;
    }
  }
`;

export const Check = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.875rem 0;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 1024px) {
    padding: 1rem 0 1.875rem;
    svg {
      width: 50px;
      height: 50px;
    }
  }
`;
