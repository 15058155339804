import {
  Wrapper,
  Container,
  ContentContainer,
  Content,
  Background,
} from "./styles";
import Title from "../Title";
import Button from "../Button";

export default function AboutUs({ onClick }) {
  return (
    <Wrapper>
      <Container>
        <Title color="#fff">Sobre nós</Title>
        <ContentContainer>
          <Content>
            <h3>
              "Não seria ótimo se você tivesse uma escola que não cobrasse
              mensalidade até você conseguir um emprego e que realmente te
              deixasse pronto para o mercado de trabalho. E tudo isso em menos
              da metade do tempo de uma faculdade tradicional?"
            </h3>

            <p>
              A Kenzie Academy é uma escola de programação de origem
              norte-americana, que transforma vidas através da educação e
              tecnologia. Essa frase resume nosso propósito e como queremos,
              cada vez mais, acelerar carreiras na área de tecnologia,
              oferecendo um ensino de qualidade, atualizado com o que mercado
              pede, tanto em hard, quanto em soft skills. Um curso, com um
              método validado por centenas de estudantes e acessível mesmo para
              quem não tem recursos para investir agora ou não possui
              conhecimento prévio em programação.
            </p>
            <Button onClick={onClick} />
          </Content>
          <Background />
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}
