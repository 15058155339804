export const moduleContents = [
  {
    modulo: "1º",
    hardskills: [
      "HTML5 e CSS3",
      "Web Semântica",
      "JavaScript (ES6)",
      "Lógica de programação",
      "Controle de versão com git",
    ],
    softskills: [
      "Aprender a aprender com os erros",
      "Gestão de tempo",
      "Produtividade",
      "Formação de novos hábitos",
      "Comunicação clara e assertiva",
      "Treinamento para entrevista técnica",
      "Colaboração",
    ],
  },
  {
    modulo: "2º",
    hardskills: [
      "JavaScript (ES6)",
      "Programação Funcional",
      "Metodologias Ágeis",
      "Qualidade",
      "Noções de UX",
      "Javascript avançado",
      "CSS avançado",
      "Interação com backend através de APIs (Application Programming Interfaces)",
      "Trabalho em equipe com git",
    ],
    softskills: [
      "Aprender a aprender com os erros",
      "Colaboração",
      "Autonomia",
      "Comunicação clara e assertiva",
      "Treinamento para entrevista técnica",
    ],
  },
  {
    modulo: "3º",
    hardskills: [
      "React",
      "Hooks",
      "State Management",
      "Redux",
      "Componentização, uso de bibliotecas de componentes",
      "Consumo de APIs",
      "Metodologias Ágeis",
    ],
    softskills: [
      "Autonomia",
      "Colaboração",
      "Construção de marca pessoal",
      "Feedback - como dar e receber",
    ],
  },
  {
    modulo: "4º",
    hardskills: [
      "NodeJs",
      "Express",
      "TypeScript",
      "Banco de dados SQL (PostgreSQL)",
      "Construção de APIs RESTful",
      "Programação assíncrona",
      "Metodologias Ágeis",
    ],
    softskills: [
      "Flexibilidade",
      "Mapeamento de competências",
      "Âncoras de carreira - Assessment",
    ],
  },
  {
    modulo: "5º",
    hardskills: [
      "ORM",
      "MongoDB",
      "Amazon AWS",
      "Migrations",
      "Metodologias Ágeis",
    ],
    softskills: ["Adaptação a novos ambientes de trabalho", "Persistência"],
  },
];
