import styled from "styled-components";

export const StyledFooter = styled.footer`
  background-color: #0a1e32;
  border-top: 1px solid #0f4c89;
`;

export const Container = styled.div`
  padding: 2rem 1.5rem 1rem;
  position: relative;
  max-width: var(--max-width);
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    padding: 4rem 2.5rem 3rem;
    margin: auto;
  }
`;

export const Logo = styled.img`
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  object-fit: contain;
  @media (min-width: 1024px) {
    position: initial;
    width: 192px;
    height: 208px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  &,
  li,
  a {
    color: var(--white);
  }
  li {
    margin-bottom: 1.5rem;
  }
  a {
    transition: color 0.3s ease-in-out;
    &:hover,
    &:focus {
      color: var(--blue-500);
    }
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    div {
      flex: 1;
    }
  }
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
`;

export const SocialList = styled.ul`
  column-count: 2;
  width: fit-content;
  font-size: 1rem;
  a {
    font-weight: 300;
  }
  @media (min-width: 1024px) {
    column-count: 1;
  }
`;

export const ContactList = styled.ul`
  li {
    margin: 1rem 0;
    font-weight: 300;
    a {
      font-weight: 400;
    }
  }
`;

export const Rights = styled.div`
  font-size: 0.875rem;
  text-align: center;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2b80d5;
  border-top: 1px solid #0f4c89;

  a {
    color: #c9c9c9;
    font-size: 1rem;
    border-bottom: 1px solid;
  }

  p {
    margin-top: 1.5rem;
  }
`;
