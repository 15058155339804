import Title from "../Title";
import { Like, Stamp } from "./icons";

import { Card, Container, Stamps, List, TitleContainer } from "./styles";

export default function InvestimentConditions() {
  return (
    <Container>
      <TitleContainer data-aos="fade-down">
        <Title margin={{ mobile: 1, desktop: 1 }}>
          Condições de investimento
        </Title>
        <p>
          Temos diversas opções de pagamento para garantir que você possa ter
          acesso ao nosso curso. Descubra qual o melhor investimento para você:
        </p>
      </TitleContainer>
      <List data-aos="fade-down">
        <Card>
          <div className="card-content">
            <div className="flex-content">
              <div>
                <div className="header-title">
                  <h4>ISA (Income Share Agreement)</h4>
                  <span>Vagas Esgotadas</span>
                </div>
                <p>
                  Com o método ISA (Income Share Agreement) você tem a
                  possibilidade de pagar pela sua formação somente após a
                  conclusão. Você só começa a pagar após possuir uma renda
                  mínima de R$2.000,00 por mês*.
                </p>
                <p className="disclaimer">
                  *Vagas limitadas. Sujeito a análise de crédito
                </p>
                <p className="disclaimer">**Confira as condições no FAQ</p>
              </div>
            </div>
            <div className="header-price">
              <h4>À VISTA</h4>
              <div className="item-content">
                <div>
                  <p className="value">
                    de <span className="sign">R$</span>{" "}
                    <span className="before">15.000</span>
                    <br />
                    por <span className="sign-after">R$</span>{" "}
                    <span className="after">9.947</span>
                  </p>
                  <p className="item-price">
                    Ideal para quem tem uma graninha guardada e quer pagar{" "}
                    <strong>mais barato</strong>.
                    <br />
                    São R$ 5.000 de desconto para te ajudar a dar o primeiro
                    passo rumo a uma carreira de sucesso em Tecnologia. E se
                    desistir no meio, devolvemos parte do dinheiro!
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="header-title">
                <h4>PARCELAMENTO</h4>
                <span>Opção mais escolhida</span>
              </div>
              <div className="item-content">
                <div>
                  <p className="value">
                    24x de <span className="sign-after">R$</span>{" "}
                    <span className="after">625</span>
                  </p>
                  <p className="item-price">
                    Opção mais escolhida pelos nossos alunos. Ideal para quem
                    quer terminar de pagar o curso mais rápido. E o melhor:{" "}
                    <strong>os 15 primeiros dias de curso são grátis</strong>{" "}
                    para você testar e sentir nossa metodologia!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </List>
      <Stamps data-aos="fade-down">
        <div className="content">
          <div>
            <Like />
          </div>
          <p>
            Nós contamos com um time totalmente dedicado ao sucesso da pessoa
            programadora e mais de 500 empresas parceiras para ajudar na
            colocação no mercado de trabalho, por isso 91% dos nossos estudantes
            conseguem contratação em até 6 meses após a formação.
          </p>
        </div>
        <div className="content">
          <div>
            <Stamp />
          </div>
          <p>
            A Kenzie Academy acredita na alta qualidade da sua formação e confia
            que você ficará satisfeito, mas se dentro de 15 dias você não se
            adaptar a metodologia da formação garantimos a devolução de 100% do
            valor investido.
          </p>
        </div>
      </Stamps>
    </Container>
  );
}
