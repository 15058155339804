import styled from "styled-components";

export const Section = styled.section`
  padding: 2.5rem 0;

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }
`;

export const Container = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  width: 85%;
  text-align: center;

  @media (min-width: 414px) {
    img {
      width: 320px;
      height: 284px;
    }
  }

  @media (min-width: 450px) {
    img {
      width: 366px;
      height: 314px;
    }
  }
  @media (min-width: 1024px) {
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);
    padding: 3rem 2rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    border-radius: 10px;
    background: var(--white);
    img {
      width: 326px;
      height: 284px;
    }
  }
  @media (min-width: 1400px) {
    padding: 5.813rem 6.688rem;
    gap: 4rem;
    img {
      width: 366px;
      height: 314px;
    }
  }
`;

export const Content = styled.div`
  p {
    color: var(--text-color);
    font-size: 1.125rem;
    padding: 1rem 0;
    text-align: left;
  }
  span {
    color: #0000ff;
    font-size: 1.25rem;
    text-align: left;
  }

  @media (min-width: 1024px) {
    height: 284px;
    display: flex;
    flex-direction: column;

    span {
      display: block;
      margin-top: auto;
      margin-bottom: 0.5rem;
    }
    p {
      max-width: 500px;
    }
  }
  @media (min-width: 1400px) {
    height: 314px;
    p {
      max-width: none;
    }
  }
`;
