import styled from "styled-components";

export const Container = styled.section`
  padding: 2.5rem 0;
  width: 85%;
  margin: 0 auto;
  max-width: var(--max-width);
  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }
`;

export const TestimonialsContent = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40rem;
  }
  @media (min-width: 1200px) {
    height: 35rem;
  }
  @media (min-width: 1300px) {
    height: 33rem;
  }
  .column-display {
    display: none;
    @media (min-width: 1024px) {
      display: flex;
    }
  }
`;

export const TestimonialColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  gap: 1rem;
  @media (min-width: 1024px) {
    width: 31%;
    height: 100%;
  }
`;
