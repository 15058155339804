import styled from "styled-components";

export const Container = styled.h2`
  font-size: 1.625rem;
  font-weight: 800;
  color: ${({ color }) => (color ? color : "#0000FF")};
  margin-bottom: ${({ margin }) =>
    margin ? `${margin.marginMobile}rem` : "2.5rem"};
  text-transform: uppercase;

  @media (min-width: 1024px) {
    margin-bottom: ${({ margin }) =>
      margin ? `${margin.marginDesk}rem` : "4.5rem"};
    font-size: 2.25rem;
  }
`;
