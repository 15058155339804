import styled from "styled-components";

export const StyledButton = styled.button`
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ blueColor }) => (blueColor ? "#0000ff" : "var(--white)")};
  background-color: ${({ color }) => color ?? "transparent"};
  border: 3px solid
    ${({ blueColor }) => (blueColor ? "#0000ff" : "var(--white)")};
  border-radius: 2px;
  padding: 1.065rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  max-width: 390px;
  transition: all 300ms ease-in-out;

  &:hover,
  &:focus {
    background-color: #000fff;
    border-color: transparent;
  }
  img {
    margin-left: 1rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.375rem;
    justify-content: center;
    max-width: 448px;
  }
`;
