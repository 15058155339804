import React from "react";
import iconCheck from "../../assets/icon_check.png";
import iconRemove from "../../assets/icon_remove.png";
import kaGroup from "../../assets/ka_group.png";
import iconPigV2 from "../../assets/icon-pig-v2.png";
import iconPrizeV2 from "../../assets/icon-trofeu-v2.png";

import {
  Container,
  Content,
  MethodsTable,
  TeamMessage,
  Cards,
  Card,
  Group,
} from "./styles";
import Title from "../Title";

const Assurance = () => {
  return (
    <Container>
      <Content>
        <Title>Compare e veja porque a Kenzie é a melhor opção!</Title>
        <MethodsTable>
          <thead>
            <tr>
              <th className="kenzie">Método Kenzie</th>
              <th className="tridicional">Método Tradicional</th>
            </tr>
          </thead>
          <tbody>
            <tr className="dark">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>10 meses</p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>4 ou 5 anos</p>
                </div>
              </td>
            </tr>
            <tr className="light">
              <td>
                {" "}
                <img src={iconCheck} alt="" />
                <p>Ensino MUITO mais prático</p>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Ensino mais teórico</p>
                </div>
              </td>
            </tr>
            <tr className="dark">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Desenvolvimento de soft skills e te damos suporte de
                    carreira
                  </p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Não desenvolve soft skills</p>
                </div>
              </td>
            </tr>
            <tr className="light">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Abrimos portas com empresas parceiras para sua contratação
                  </p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>
                    Você conclui o curso, sem garantia de empregabilidade e
                    sucesso
                  </p>
                </div>
              </td>
            </tr>
            <tr className="dark">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>100% online, com aulas ao vivo</p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>EAD ou presencial</p>
                </div>
              </td>
            </tr>
            <tr className="light">
              <td>
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Suporte personalizado, com dúvidas respondidas em até 5
                    minutos
                  </p>
                </div>
              </td>
              <td>
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Suporte padrão</p>
                </div>
              </td>
            </tr>
            <tr className="dark">
              <td className="last-kenzie">
                <div>
                  {" "}
                  <img src={iconCheck} alt="" />
                  <p>
                    Comunidade online no Slack para tirar dúvidas e networking
                  </p>
                </div>
              </td>
              <td className="last-traditional">
                <div>
                  {" "}
                  <img src={iconRemove} alt="" />
                  <p>Sem comunidade</p>
                </div>
              </td>
            </tr>
          </tbody>
        </MethodsTable>
        <TeamMessage>
          <div className="text-box">
            <h3>Nós só temos sucesso se você tiver sucesso!</h3>
            <p>
              Aqui na Kenzie, você não precisa pagar nada para começar a estudar
              -{" "}
              <strong>
                Você estuda por 10 meses sem se preocupar com mensalidades.{" "}
              </strong>
              Isso mesmo, nós oferecemos a possibilidade de você pagar o curso
              somente após o seu ingresso no mercado de trabalho. Se sua renda,
              após o curso, ultrapassar R$ 2.000 no mês, aí você paga 20% para
              Kenzie.
            </p>
          </div>
          <div className="image-box">
            <img src={kaGroup} alt="Daniel" />
          </div>
        </TeamMessage>
        <Group>
          <Cards>
            <Card>
              <img
                src={iconPigV2}
                alt="Ícone cofrinho de moedas"
                width="60"
                height="60"
              />
              <div>
                <h4>Risco zero</h4>
                <p>
                  O valor do 20% é só se você conseguir uma remuneração a partir
                  de R$ 2.000 por mês, se a sua renda for inferior a esse valor,
                  não precisa pagar nada naquele mês.
                </p>
              </div>
            </Card>
            <Card>
              <img
                src={iconPrizeV2}
                alt="Ícone troféu"
                width="60"
                height="60"
              />
              <div>
                <h4>Garantia de sucesso</h4>
                <p>
                  Acreditamos tanto no potencial profissional que você vai ter
                  com nosso método, que temos praticamente a certeza que se você
                  se esforçar, se dedicar e levar a sério, a sua colocação no
                  mercado será quase 100% garantida, e dessa maneira, você terá
                  uma renda muito maior que R$ 2.500!
                </p>
              </div>
            </Card>
          </Cards>
        </Group>
      </Content>
    </Container>
  );
};

export default Assurance;
