import styled from "styled-components";

export const Container = styled.section`
  width: 85%;
  margin: 0 auto;
  color: #0000ff;
  text-align: center;
  max-width: var(--max-width);

  h2 {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 2.5rem;
    font-weight: 800;
    font-size: 1.625rem;
    line-height: 2.5rem;
    color: #0000ff;

    @media (min-width: 1024px) {
      margin-bottom: 4.5rem;
      font-size: 2.25rem;
      line-height: 3.5rem;
    }
  }
`;

export const Content = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
    min-height: 525px;
  }
`;

export const DailyOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  color: var(--white);
  box-shadow: 2px 1px 3px 0 rgb(0 0 0 / 10%);

  @media (min-width: 1024px) {
    flex-direction: column;
    width: 20%;
    border-bottom-left-radius: 20px;
  }

  button {
    min-height: 4rem;
    width: 100%;
    color: #2d2d2d;
    font-family: "Montserrat", sans-serif;
    background-color: #eaeaea;
    font-weight: bold;
    transition: all 200ms ease-in;
    outline: none;

    @media (min-width: 1024px) {
      height: 100%;
      font-size: 1rem;
      padding: 1.5rem;
    }
  }

  button:first-child {
    border-top-left-radius: 0.5rem;

    @media (min-width: 1024px) {
      border-top-left-radius: 1.25rem;
    }
  }

  button:last-child {
    border-top-right-radius: 0.5rem;

    @media (min-width: 1024px) {
      border-bottom-left-radius: 1.25rem;
      border-top-right-radius: 0;
    }
  }

  button.active {
    background-color: #0000ff;
    color: var(--white);
  }
`;

export const DailyContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: #ffffff;
  text-align: left;
  font-size: 1.2rem;
  box-shadow: 2px 1px 3px 0 rgb(0 0 0 / 10%);

  .note {
    margin-top: 0;
    font-size: 0.8rem;
    font-weight: 300;
  }

  @media (min-width: 1024px) {
    width: 80%;
    padding: 3rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    color: #2d2d2d;
  }

  span {
    font-weight: 700;
    color: #0000ff;
    margin: 1rem 0;
  }

  p {
    line-height: 1.8rem;
    margin-bottom: 0;
    color: #2d2d2d;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
`;
