import styled from "styled-components";

export const Container = styled.section`
  padding: 2.5rem 0;
  width: 85%;
  margin: 0 auto;
  max-width: var(--max-width);

  @media (min-width: 1024px) {
    padding: 4.5rem 0;
  }
`;

export const Text = styled.p`
  font-size: 1.15rem;
  margin-bottom: 2.5rem;

  @media (min-width: 1024px) {
    font-size: 1.25rem;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-top: 2.5rem;
    max-width: 350px;
  }

  button:hover,
  button:focus {
    background-color: initial;
    border-color: initial;
    filter: brightness(0.5);
  }

  @media (max-width: 1023px) {
    li:nth-child(1) div {
      border-radius: 20px 20px 0 0;
    }

    li:nth-child(5) div {
      border-radius: 0 0 20px 20px;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
